import React from 'react';
import QRCode from 'qrcode.react';
import { Centrifuge } from 'centrifuge';
import qs from 'qs';
import axios from 'axios';

import './styles/water.css';
import './styles/custom.css';

import logo from './logo.png';

require('dotenv').config();

class Qr extends React.Component {
    render() {
        const website = window.location.origin;

        const link = website + '?t=' + this.props.hash;
        // console.log('hash' + this.props.hash + ' ' + link);
        return (
            <div style={{ margin: '0 40px' }}>
                <QRCode size={512} value={link} style={{ width: '100%', height: '100%', maxWidth: '350px' }} />
            </div>
        )
    };
}

class App extends React.Component {
    constructor(props) {
        super(props);
        // Не вызывайте здесь this.setState()!

        const token = qs.parse(document.location.search, { ignoreQueryPrefix: true }).t;

        this.state = {
            // system
            hash: (token) ?? Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            centrifugeObject: null,

            // input
            fade: false,
            copyButtonText: 'copy',
            value: '',
            sentValue: '',
            typing: false,
        };
    }

    gen = () => {
        this.setState({
            'hash': Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        })
    };

    handleChange(event) {
        const message = event.target.value;
        this.setState({
            value: message,
            typing: true,
        });
    }

    send = (message) => {
        const channel = this.getChannel();

        this.state.centrifugeObject.publish(channel, {"message": message}).then(function(res) {
            console.log('successfully published:', message);
        }, function(err) {
            console.log('publish error', err);
        })
    };

    getChannel() {
        return 'clipboard:' + this.state.hash;
    }

    async getTokens() {
        return await axios.post(
            process.env.REACT_APP_BACKEND_API + '/token',
            {
                client: this.state.hash,
                channel: this.getChannel()
            }
        )
            .then((r) => {
                return r.data
            })
    }

    async componentDidMount() {

        let tokens = await this.getTokens()
        let general = tokens.general
        let subscribe = tokens.sub

        let centrifuge = new Centrifuge(process.env.REACT_APP_CENTRIFUGE_URL, {
            token: general
        });

        this.setState({
            centrifugeObject: centrifuge
        })

        this.state.centrifugeObject.connect();

        const channel = this.getChannel();
        console.log('channel:', channel)

        const sub = this.state.centrifugeObject.newSubscription(channel, {
            token: subscribe
        });
        sub.subscribe();

        // React on `news` channel real-time publications.
        sub.on('publication', (ctx) => {

            let message = ctx.data.message
            let our_id = sub._centrifuge._client
            let message_sender_id = ctx.info.client

            if (our_id !== message_sender_id) {
                console.log('received message:', message);
                this.setState({
                    value: message
                })
            }
        });

        // Fetch from history
        sub.history({limit: 1})
            .then(response => {
                if (response.publications.length > 0) {
                    let message = response.publications[0].data.message;
                    this.setState({
                        value: message
                    })
                } else {
                    console.log('empty')
                }
            });

        this.timeout = setInterval(() => {
            this.sendOnChange();
        }, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    sendOnChange() {
        let msg = document.getElementById('board').value;

        if (this.state.sentValue !== msg && this.state.typing) {
            this.setState({
                sentValue: msg,
                typing: false,
            });

            this.send(msg);
        }
    }

    fireAnim = () => {
        this.setState({ fade: true });
        this.setState({ copyButtonText: 'copied' });
        const el = this.boardArea;
        el.select();
        document.execCommand("copy");
    };

    stopAnim = () => {
        this.setState({ fade: false });
        this.setState({ copyButtonText: 'copy' });
    };

    render() {
        return (
            <div className="App" style={{ minWidth: '250px' }}>

                <a href="#" style={{ userSelect: 'none' }} >
                    <p style={{fontSize: 20, color: 'black', fontWeight: 700}}>{'<textarea>'}</p>
                </a>

                <div style={{ textAlign: 'center', margin: 20 }}>
                    <p>scan code for syncing</p>
                    <Qr hash={this.state.hash} />
                </div>

                <div>
                    <button
                        style={{ width: '100%' }}
                        onClick={() => this.fireAnim()}
                        onAnimationEnd={() => this.stopAnim()}
                        className={this.state.fade ? 'btn2' : 'btn1'}
                    >
                        {this.state.copyButtonText}
                    </button>
                    <textarea
                        ref={(textarea) => this.boardArea = textarea}
                        id="board"
                        onChange={(event) => this.handleChange(event)}
                        value={this.state.value}
                        style={{minHeight: 150}}
                        placeholder='paste your clipboard here...'
                    >
                    </textarea>
                </div>

                <div style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                    <div style={{  textAlign: 'right' }}>
                        say hi <a target='_blank' href="https://twitter.com/kkachaloff">@kkachaloff</a>
                        <br />
                        or text <i>hi@kachalov.xyz</i>
                    </div>

                    <div style={{  textAlign: 'left' }}>
                        my new project!<br/>
                        personalised ai book generator <a target='_blank' href="https://bybookai.com?ref=textarea">ByBookai.com</a>
                    </div>
                </div>


            </div>
        );
    }
}

export default App;
